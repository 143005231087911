<template>
  <b-container fluid>
    <b-modal id="my-modal" title="Empresas" size="md" hide-footer>
      <div class="text-center">
        <img
          class="img-fluid"
          style="
            max-width: 400px;
            margin-left: 0 auto !important;
            margin-right: 0 auto !important;
          "
          :src="logoEmpresa"
          alt=""
        />
      </div>
    </b-modal>

    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <span class="card-title my-2 h4"><b>LISTA DE EMPRESAS</b></span>
          </template>
          <div class="text-right px-4 mt-4 responsive-xl-md">
            <div class="d-flex justify-content-end">
              <vs-button
                icon
                animation-type="vertical"
                success
                class="mx-1 text-right"
                @click="$router.push('Empresas_agregar')"
              >
                <i class="fas fa-plus px-1"></i>
                Agregar Empresa
                <template #animate>
                  <i class="fas fa-plus"></i>
                </template>
              </vs-button>
            </div>
          </div>
          <!-- button-sm -->
          <div
            class="text-right px-4 mt-4 pb-0 mb-0 responsive-sm"
            style="margin-bottom: -73px !important"
          >
            <div class="d-flex justify-content-end">
              <vs-button
                success
                class=""
                style="
                  background-color: rgb(54, 153, 100) !important;
                  display: block !important;
                "
                @click="$router.push('Empresas_agregar')"
                ><i class="fas fa-plus"></i
              ></vs-button>
            </div>
          </div>
          <!-- end button-sm -->
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_empresa"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre Empresa</th>
                  <th>Imagen</th>
                  <th>Sector</th>
                  <th>Coreo Electrónico</th>
                  <th>Teléfono</th>
                  <th>Estado</th>

                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.nombre }}</td>
                  <td class="px-3 text-center">
                    <vs-avatar
                      class="hover_avatar"
                      @click="
                        logoEmpresa = item.urlLogo;
                        $bvModal.show('my-modal');
                      "
                    >
                      <img class="p-0 m-0" :src="item.urlLogo" alt="" />
                    </vs-avatar>
                  </td>
                  <!-- <td class="p-0 mx-1">
                    <vs-button class="p-0 m-0" animation-type="scale" border  color="rgb(255, 255, 255)" icon style="background-color: transparent !important;">
                      <vs-avatar class="p-0 m-0">
                        
                        <img
                          @click="
                            logoEmpresa = item.urlLogo;
                            $bvModal.show('my-modal');
                          "
                          class="p-0 m-0"
                          :src="item.urlLogo"
                          alt=""
                        />
                        
                      </vs-avatar>
                      <template  #animate >
                          <i class="fas fa-eye" ></i>
                        </template>
                    </vs-button>
                  </td> -->
                  <td>{{ item.sector.descripcion }}</td>
                  <td>{{ item.correo }}</td>
                  <td>{{ item.telefonoContacto }}</td>
                  <td>{{ item.estado == 1 ? "Activo" : "Inactivo" }}</td>
                  <td style="width: 150px !important">
                    <ButtonGroup :Id="item.id" :data="dataIcons"></ButtonGroup>
                  </td>

                  <!-- <td>
                    <span class="pointer"><i @click="$router.push('Empresas_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success"></i> 
                     <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i></span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      itemId: null,
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar empresa",
          title: "",
          value: () => 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Empresas_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        //        {
        //          type: "warning",
        //          tooltip: "Crear lista de deducciones y devengados para nómina ",
        //          title: "",
        //          value: () => 0,
        //          iconName: "cog",
        //          color: "rgba(22, 162, 117, 1)",
        //          tipo: "squared",
        //          animated: "bounce",
        //          onActivate: (Id) => {
        //            this.CrearItemsNomina(Id);
        //            console.log("Activated");
        //          },
        //          onDeactivate: () => {
        //            console.log("Deactivate");
        //          },
        //        },
        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    core.index();
    await this.getData();
    window.$("#datatable_empresa").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },
  methods: {
    async CrearItemsNomina(EmpresaId) {
      try {
        this.$isLoading(true);

        let res = await this.$store.dispatch("hl_post", {
          path:
            "NominaLiquidacion/CrearNominaLiquidacionItemsEmpresa/" + EmpresaId,
        });
        this.$isLoading(false);
        if (res)
          Swal.fire("Listo!", "Proceso finalizado correctamente.", "success");
        else
          Swal.fire("Error!", "Ya existen items para esta empresa.", "error");
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
        Swal.fire("Error!", "Error: " + error, "error");
      }
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Empresa/delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
/* .vs-avatar{
background-color: rgb(255, 255, 255) !important;
}
.vs-avatar:hover{
  background-image: url(https://www.w3schools.com/cssref/img_5terre.jpg);
  img

}  */
</style>
